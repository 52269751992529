import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/layouts/text.tsx";
import { CodepenDemo } from '@components/codepenDemo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Step 1: Isolate the bug on your end`}</h2>
    <p>{`More often than not, the problem is with your code, not with FullCalendar. To determine this, remove as much of your code as possible:`}</p>
    <ul>
      <li parentName="ul">{`Remove all unrelated stylesheets and JS files`}</li>
      <li parentName="ul">{`Remove all unrelated 3rd party libraries`}</li>
      <li parentName="ul">{`Remove all server-side logic. Use static HTML and JSON files instead.`}</li>
    </ul>
    <p>{`For more info on this technique, read `}<a parentName="p" {...{
        "href": "http://css-tricks.com/reduced-test-cases/"
      }}>{`Reduced Test Cases`}</a>{`.`}</p>
    <h2>{`Step 2: Post a public demonstration of the bug`}</h2>
    <p>{`Use a service like `}<a parentName="p" {...{
        "href": "http://codepen.io/"
      }}>{`Codepen`}</a>{` or `}<a parentName="p" {...{
        "href": "http://jsfiddle.net/"
      }}>{`JSFiddle`}</a>{` to demonstrate the bug online. Enter your JS, CSS, HTML, and JSON and wire up all the relevant dependencies. To help you get started, here are some `}<strong parentName="p">{`debugging templates:`}</strong></p>
    {/* NOTE: when updating these URLs, also update in individual doc articles */}
    <h3>{`FullCalendar v6`}</h3>
    <ul>
      <li parentName="ul">
        <CodepenDemo codepenDataUrl='/docs/initialize-globals-demo' label='Standard' mdxType="CodepenDemo" /> (<CodepenDemo codepenDataUrl='/docs/debug-json-feed' label='with JSON feed' mdxType="CodepenDemo" />)
      </li>
      <li parentName="ul">
        <CodepenDemo codepenDataUrl='/docs/debug-scheduler' label='Premium plugins' mdxType="CodepenDemo" /> (<CodepenDemo codepenDataUrl='/docs/debug-scheduler-json-feed' label='with JSON feed' mdxType="CodepenDemo" />)
      </li>
      <li parentName="ul">
        <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/main/react' target='_blank'>React</a>
      </li>
      <li parentName="ul">
        <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/main/vue3' target='_blank'>Vue 3
        </a> | <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/main/vue2' target='_blank'>2
        </a>
      </li>
      <li parentName="ul">
        <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/main/angular17' target='_blank'>Angular 17
        </a> | <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/main/angular16' target='_blank'>16
        </a> | <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/main/angular15' target='_blank'>15
        </a> | <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/main/angular14' target='_blank'>14
        </a> | <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/main/angular13' target='_blank'>13
        </a> | <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/main/angular12' target='_blank'>12
        </a> (if using Brave, ensure your <a href='https://developer.stackblitz.com/platform/webcontainers/browser-config-brave'>"shields are down"</a>)
      </li>
    </ul>
    <h3>{`FullCalendar v5`}</h3>
    <ul>
      <li parentName="ul">
        <CodepenDemo codepenDataUrl='/docs/v5/initialize-globals-demo' label='Standard' mdxType="CodepenDemo" /> (<CodepenDemo codepenDataUrl='/docs/v5/debug-json-feed' label='with JSON feed' mdxType="CodepenDemo" />)
      </li>
      <li parentName="ul">
        <CodepenDemo codepenDataUrl='/docs/v5/debug-scheduler' label='Premium plugins' mdxType="CodepenDemo" /> (<CodepenDemo codepenDataUrl='/docs/v5/debug-scheduler-json-feed' label='with JSON feed' mdxType="CodepenDemo" />)
      </li>
      <li parentName="ul">
        <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/v5/react' target='_blank'>React</a>
      </li>
      <li parentName="ul">
        <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/v5/vue3-typescript' target='_blank'>Vue 3
        </a> | <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/v5/vue2' target='_blank'>2
        </a>
      </li>
      <li parentName="ul">
        <a href='https://stackblitz.com/github/fullcalendar/fullcalendar-examples/tree/v5/angular12' target='_blank'>Angular 12
        </a> (if using Brave, ensure your <a href='https://developer.stackblitz.com/platform/webcontainers/browser-config-brave'>"shields are down"</a>)
      </li>
    </ul>
    <h3>{`FullCalendar v4`}</h3>
    <ul>
      <li parentName="ul">
        <CodepenDemo codepenDataUrl='/docs/v4/initialize-globals-demo' label='Standard' mdxType="CodepenDemo" /> (<CodepenDemo codepenDataUrl='/docs/v4/debug-json-feed' label='with JSON feed' mdxType="CodepenDemo" />)
      </li>
      <li parentName="ul">
        <CodepenDemo codepenDataUrl='/docs/v4/debug-scheduler' label='Premium plugins' mdxType="CodepenDemo" /> (<CodepenDemo codepenDataUrl='/docs/v4/debug-scheduler-json-feed' label='with JSON feed' mdxType="CodepenDemo" />)
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      